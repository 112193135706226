$primary: #1c1c1c !default; $secondary: #f9fcff !default; $tertiary: #1d6d6d !default; $quaternary: #cb3727 !default; $header_background: #cb3727 !default; $header_primary: #ffffff !default; $highlight: #e6cb37 !default; $danger: #d05454 !default; $success: #71bd9f !default; $love: #c16ad7 !default; $selected: #d2edf3 !default; $hover: #d2edf3 !default;  $public_image_path: "https://ghdev.cnvc.org/images";  @import "common/foundation/variables"; @import "common/foundation/mixins"; // Theme: Discourse Clickable Topic
// Target: common scss
// Last Edited: 2024-02-06 08:47:57 UTC
.topic-list-item,
.latest-topic-list-item {
  &:hover {
    cursor: pointer;
    background: rgba(var(--primary-rgb), 0.025);
  }
}
